<template>
  <!-- <modal name="modal-cadastro-consulta" scrollable height="auto" width="900"> -->
  <div class="cadastro-consulta-container camp-container">
    <ModalConfirmacaoSair />

    <div class="camp-header">
      <div class="back-arrow-squared" @click="showModalConfirmacaoSair">
        <i class="flaticon-up-arrow" style="color: #808080 !important"></i>
      </div>
      <b-button variant="success" @click="salvarConsulta" class="text-uppercase"
        >Salvar Consulta</b-button
      >
    </div>
    <!-- cca_nome -->
    <div class="">
      <label for="nome">Nome</label>
      <b-form-input
        id="nome"
        v-model="objetoParaEnviarNoCadastro.nome"
        style="width: 100%"
      ></b-form-input>
    </div>
    <LicenciadaTipoModelo
      :licenciadaOpcoes="licenciada.dropdown.opcoes"
      :modeloTiposOpcoes="modeloTipos.dropdown.opcoes"
      :modeloPossiveisOpcoes="modelosPossiveisVariable"
      v-on:pegarLicenciadasDoBanco="pegarLicenciadasDoBanco"
      v-on:modeloPossiveis="modeloPossiveis"
      v-on:selecionarModelo="selecionarModelo"
      :sistemaLicenciadaId="objetoParaEnviarNoCadastro.sistema_licenciada_id"
      :modeloTipos="modeloTipos.dropdown.selecionado"
      :modelo="modelo.dropdown.selecionado"
    />
    <!-- <b-form-select
            class="w-100"
            name="licenciada"
            :options="licenciadaOpcoes"
            @change="pegarLicenciadasDoBanco"
            v-model="modeloChatbotSelecionado"
          ></b-form-select>
        </b-form-group> -->
    <div class="d-flex w-100 align-items-end">
      <button
        class="btn botao-filtro-add text-uppercase"
        @click="adicionarNovoFiltro"
      >
        + Adicionar novo filtro
      </button>
    </div>
    <hr />
    <nested-draggable
      :group="0"
      :listaDeFiltros="listaDeFiltros"
      :camposPossiveisDoFiltro="camposPossiveisDoFiltro"
      :opreadoresFiltro="operadores"
      class="col mt-5"
      :isDraggable="true"
      :sistemaLicenciadaId="objetoParaEnviarNoCadastro.sistema_licenciada_id"
    >
    </nested-draggable>
  </div>
</template>

<script>
import API_LINKS from "../../api.links";
import ApiService from "@/core/services/api.service";
import LicenciadaTipoModelo from "../../campanha/cadastro-componentes/LicenciadaTipoModelo.vue";
import nestedDraggable from "../../campanha/cadastro-componentes/NestedDraggable.vue";
import ModalConfirmacaoSair from "../../campanha/cadastro-componentes/ModalConfirmacaoSair.vue";

export default {
  components: {
    LicenciadaTipoModelo,
    nestedDraggable,
    ModalConfirmacaoSair
  },
  data() {
    return {
      operadores: [],
      modelosPossiveisVariable: [],
      listaDeFiltros: [],
      opreadoresFiltro: [],
      camposPossiveisDoFiltro: [],
      objetoParaEnviarNoCadastro: {
        sistema_licenciada_id: -1,
        modelo_id: -1,
        modelo_tipo_id: -1,
        nome: ""
      },
      licenciada: {
        bancos: [],
        dropdown: {
          selecionado: {
            value: -1,
            text: "Selecione a licenciada"
          },
          opcoes: [
            {
              value: 0,
              text: "MS HTO"
            },
            {
              value: 1,
              text: "MS SQL Bambino"
            },
            {
              value: 2,
              text: "MS Server"
            }
          ]
        }
      },
      modelo: {
        dropdown: {
          selecionado: {
            value: -1,
            text: "Selecione o Modelo"
          },
          modelosPossiveis: []
        }
      },
      modeloTipos: {
        dropdown: {
          selecionado: {
            value: -1,
            text: "Selecione o Tipo"
          },
          opcoes: []
        }
      }
    };
  },
  mounted() {
    // params consultaItem
    const params = this.$route.params;
    const init = async () => {
      await this.obterOpcoesLicenciada();
      await this.pegarOperadores();

      if (params.consultaItem) {
        const consulta = await this.obterConsulta(
          params.consultaItem.chatbot_campanha_id
        );
        this.preencherDadosConsultaEdicao(consulta);
      }
    };
    init();
  },
  watch: {
    listaDeFiltros: {
      handler(val, oldVal) {
        function recursiveRemoveLastMfiEOu(lista) {
          if (lista[lista.length - 1].listaDeFiltros.length === 0) {
            delete lista[lista.length - 1].mfi_e_ou;
            return;
          }
          recursiveRemoveLastMfiEOu(lista[lista.length - 1].listaDeFiltros);
        }
        function removeNull(lista) {
          const listaInitialLength = lista.length;
          for (let i = 0; i < listaInitialLength; i++) {
            if (lista[i] === null) {
              const removedElement = lista.splice(i, 1);
            }
          }
        }

        // Esse método tá bugado pakas.
        function removeLastMfiEOu(lista) {
          // Último elemento de hierarquia 0
          // lista[lista.length - 1]

          // Último elemento de hierarquia 1
          // lista[lista.length - 1].listaDeFiltros[
          //         lista[lista.length - 1].listaDeFiltros.length - 1
          //       ]

          // Último elemento de hierarquia 2
          // lista[lista.length - 1].listaDeFiltros[
          //   lista[lista.length - 1].listaDeFiltros.length - 1
          // ].listaDeFiltros[
          //   lista[lista.length - 1].listaDeFiltros[
          //     lista[lista.length - 1].listaDeFiltros.length - 1
          //   ].listaDeFiltros.length - 1
          // ]

          // Verifica se a lista tem algum elemento
          if (lista.length > 0) {
            // Verifica se a lista do último elemento da lista de hierarquia 0 tem algum elemento
            if (lista[lista.length - 1].listaDeFiltros.length > 0) {
              // Verifica se o último elemento da lsita de hierarquia 1 tem algum elemento
              if (
                lista[lista.length - 1].listaDeFiltros[
                  lista[lista.length - 1].listaDeFiltros.length - 1
                ].listaDeFiltros.length > 0
              ) {
                // console.log(
                //   "última lista de hierarquia 2",
                //   lista[lista.length - 1].listaDeFiltros[
                //     lista[lista.length - 1].listaDeFiltros.length - 1
                //   ].listaDeFiltros[
                //     lista[lista.length - 1].listaDeFiltros[
                //       lista[lista.length - 1].listaDeFiltros.length - 1
                //     ].listaDeFiltros.length - 1
                //   ]
                // );
                const listThree =
                  lista[lista.length - 1].listaDeFiltros[
                    lista[lista.length - 1].listaDeFiltros.length - 1
                  ].listaDeFiltros;
                if (listThree[listThree.length - 1].listaDeFiltros.length > 0) {
                  delete listThree[listThree.length - 1].listaDeFiltros[
                    listThree[listThree.length - 1].listaDeFiltros.length - 1
                  ].mfi_e_ou;
                } else {
                  delete lista[lista.length - 1].listaDeFiltros[
                    lista[lista.length - 1].listaDeFiltros.length - 1
                  ].listaDeFiltros[
                    lista[lista.length - 1].listaDeFiltros[
                      lista[lista.length - 1].listaDeFiltros.length - 1
                    ].listaDeFiltros.length - 1
                  ].mfi_e_ou;
                }
              } else {
                // console.log(
                //   "última lista de hierarquia 1",
                //   lista[lista.length - 1].listaDeFiltros[
                //     lista[lista.length - 1].listaDeFiltros.length - 1
                //   ]
                // );
                delete lista[lista.length - 1].listaDeFiltros[
                  lista[lista.length - 1].listaDeFiltros.length - 1
                ].mfi_e_ou;
              }
            } else {
              delete lista[lista.length - 1].mfi_e_ou;
              // console.log(
              //   "última lista de hierarquia 0",
              //   lista[lista.length - 1]
              // );
            }
          }
        }
        // Adicionar o mfi_e_ou em todos os elementos e eliminar o último
        function recursiveAddMfiToAllFilters(lista) {
          if (lista.length === 0) {
            return;
          }
          for (let i = 0; i < lista.length; i++) {
            if (lista[i].listaDeFiltros.length > 0) {
              recursiveAddMfiToAllFilters(lista[i].listaDeFiltros);
            } else if (!lista[i].hasOwnProperty("mfi_e_ou")) {
              lista[i].mfi_e_ou = "AND";
            }
          }
        }
        function addMfiToAllFilters(lista) {
          if (lista.length > 0) {
            lista.forEach((filtro0) => {
              if (
                !filtro0.hasOwnProperty("mfi_e_ou") &&
                filtro0.listaDeFiltros.length === 0
              ) {
                filtro0.mfi_e_ou = "AND";
              }
              if (filtro0.listaDeFiltros.length > 0) {
                filtro0.listaDeFiltros.forEach((filtro1) => {
                  if (
                    !filtro1.hasOwnProperty("mfi_e_ou") &&
                    filtro1.listaDeFiltros.length === 0
                  ) {
                    filtro1.mfi_e_ou = "AND";
                  }
                  if (filtro1.listaDeFiltros.length > 0) {
                    filtro1.listaDeFiltros.forEach((filtro2) => {
                      if (
                        !filtro2.hasOwnProperty("mfi_e_ou") &&
                        filtro2.listaDeFiltros.length === 0
                      ) {
                        filtro2.mfi_e_ou = "AND";
                      }
                      if (filtro2.listaDeFiltros.length > 0) {
                        filtro2.listaDeFiltros.forEach((filtro3) => {
                          if (!filtro3.hasOwnProperty("mfi_e_ou")) {
                            filtro3.mfi_e_ou = "AND";
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        }

        function changeHierarchyOfLastElement(lista, initialHierarchy = 0) {
          if (lista[lista.length - 1].listaDeFiltros.length !== 0) {
            changeHierarchyOfLastElement(
              lista[lista.length - 1].listaDeFiltros,
              lista[lista.length - 1].hierarquia
            );
          }
          lista[lista.length - 1].hierarquia = initialHierarchy;
        }

        addMfiToAllFilters(val);
        // recursiveAddMfiToAllFilters(val);
        removeLastMfiEOu(val);
        // changeHierarchyOfLastElement(val);
        // recursiveRemoveLastMfiEOu(val);

        this.$forceUpdate();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async preencherDadosConsultaEdicao(consulta) {
      this.objetoParaEnviarNoCadastro.nome = consulta.cca_nome;

      await this.pegarLicenciadasDoBanco(consulta.sistema_licenciada_id);

      const modelos_lista = this.modelo.dropdown.modelosPossiveis;
      const modelo_tipo = modelos_lista.find(
        (modelo) => modelo.value === consulta.modelo_id
      );

      this.modeloTipos.dropdown.selecionado = modelo_tipo.mt_tipo;
      this.modelosPossiveisVariable = modelos_lista.filter(
        (modelo) => modelo.mt_tipo === modelo_tipo.mt_tipo
      );

      await this.selecionarModelo(consulta.modelo_id);
      this.listaDeFiltros = consulta.FILTROS;
    },
    async obterConsulta(id) {
      const response = await ApiService.get(
        API_LINKS.chatbot_consulta + "/" + id
      );

      return response.data.campanha;
    },
    showModalConfirmacaoSair() {
      this.$modal.show("modal-confirmacao-sair");
    },
    cadastrarNovaConsulta() {
      const payload = { campanha: {} };
      payload.campanha.chatbot_campanha_id = null;
      payload.campanha.modelo_id = this.objetoParaEnviarNoCadastro.modelo_id;
      payload.campanha.sistema_licenciada_id =
        this.objetoParaEnviarNoCadastro.sistema_licenciada_id;
      payload.campanha.cca_nome = this.objetoParaEnviarNoCadastro.nome;
      payload.campanha.filtros = this.listaDeFiltros;

      ApiService.post(API_LINKS.chatbot_consulta, payload).then((response) => {
        this.$router.push({
          name: "automacao",
          params: {
            navSelecionada: 4,
            toast: {
              severity: "success",
              summary: "Consulta Salva!",
              detail: "Consulta salva com sucesso.",
              life: 5000
            }
          }
        });
      });
    },
    editarConsulta(consulta) {
      const payload = { campanha: {} };
      payload.campanha.chatbot_campanha_id = consulta.chatbot_campanha_id;
      payload.campanha.modelo_id = this.objetoParaEnviarNoCadastro.modelo_id;
      payload.campanha.sistema_licenciada_id =
        this.objetoParaEnviarNoCadastro.sistema_licenciada_id;
      payload.campanha.cca_nome = this.objetoParaEnviarNoCadastro.nome;
      payload.campanha.filtros = this.listaDeFiltros;

      ApiService.post(API_LINKS.chatbot_consulta, payload).then(() => {
        this.$router.push({
          name: "automacao",
          params: {
            navSelecionada: 4,
            toast: {
              severity: "success",
              summary: "Consulta Salva!",
              detail: "Consulta salva com sucesso.",
              life: 5000
            }
          }
        });
      });
    },
    salvarConsulta() {
      if (this.$route.params.consultaItem) {
        this.editarConsulta(this.$route.params.consultaItem);
      } else {
        this.cadastrarNovaConsulta();
      }
    },
    adicionarNovoFiltro() {
      this.listaDeFiltros.push({
        hierarquia: 0,
        filtro_id: 1,
        mfi_valor: "",
        ope_titulo: "",
        mfi_e_ou: "AND",
        mfi_valor_2: null, //(apenas se for um between),
        operador_id: 12, //(cada condição vai ter um id),
        listaDeFiltros: [],
        contexto_id: null
      });
      this.listaDeFiltros.forEach((v, i) => {
        if (!(Array.isArray(v.listaDeFiltros) && v.listaDeFiltros.length)) {
          if (!v.mfi_e_ou) v.mfi_e_ou = "AND";
        }
        delete this.listaDeFiltros[this.listaDeFiltros.length - 1].mfi_e_ou;
      });
    },
    async pegarOperadores() {
      const response = await ApiService.get(API_LINKS.campanha + "/operador");
      this.operadores = response.data.operadores.map((v) => {
        return {
          ope_titulo: v.ope_titulo,
          ope_sinal: v.ope_sinal,
          operador_id: v.operador_id
        };
      });

      return response;
    },
    async obterOpcoesLicenciada() {
      const response = await ApiService.get(API_LINKS.sistemaLicenciada);
      this.licenciada.dropdown.opcoes = response.data.sistemas.map((v) => {
        return {
          value: v.sistema_licenciada_id,
          text: v.sli_titulo
        };
      });
      this.licenciada.bancos = response.data.sistemas.map((v) => {
        return {
          sistema_licenciada_id: v.sistema_licenciada_id,
          sistema_banco_id: v.sistema_banco_id
        };
      });
      return response;
    },
    async selecionarModelo(modeloSelecionado) {
      if (modeloSelecionado) {
        this.modelo.dropdown.selecionado = modeloSelecionado;
      }

      const options = this.modelo.dropdown.modelosPossiveis.filter((v) => {
        return this.modeloTipos.dropdown.selecionado == v.mt_tipo;
      });

      if (typeof modeloSelecionado !== "undefined") {
        this.objetoParaEnviarNoCadastro.modelo_id = modeloSelecionado;
      } else {
        this.modelo.dropdown.selecionado = options.find(
          (v) => v.value === this.objetoParaEnviarNoCadastro.modelo_id
        ).value;
      }

      await this.getCampanhaFiltros().then((res) => {
        this.camposPossiveisDoFiltro = res.data.filtros.map((filtro) => {
          return {
            fil_campo: filtro.fil_campo,
            fil_titulo: filtro.fil_titulo,
            filtro_id: filtro.filtro_id,
            ope_titulo: filtro.ope_titulo,
            fil_tipo_dado: filtro.fil_tipo_dado,
            fil_tipo: filtro.fil_tipo,
            fil_mascara: filtro.fil_mascara
          };
        });
      });
    },
    async pegarLicenciadasDoBanco(licenciadaId) {
      if (licenciadaId) {
        this.objetoParaEnviarNoCadastro.sistema_licenciada_id = licenciadaId;
      }
      this.modelo.dropdown.selecionado = {
        value: -1,
        text: "Selecione o Modelo"
      };
      this.modeloTipos.dropdown.selecionado = {
        value: -1,
        text: "Selecione o Tipo"
      };
      const bancoId = this.encontrarBancoIdDaLicenciadaSelecionada();
      await ApiService.get(API_LINKS.campanhaModelo + "/" + bancoId).then(
        (res) => {
          this.modelo.dropdown.modelosPossiveis = res.data.modelos.map((v) => {
            return {
              value: v.modelo_id,
              text: v.md_descricao,
              mt_tipo: v.mt_tipo
            };
          });
          this.modelosPossiveisVariable = this.modelo.dropdown.modelosPossiveis;

          const arrDeOpcoesDeModelosTotais = res.data.modelos.map((v) => {
            return {
              ...v,
              text: v.mt_titulo,
              value: v.mt_tipo
            };
          });

          this.modeloTipos.dropdown.opcoes = [
            ...new Map(
              arrDeOpcoesDeModelosTotais.map((item) => [item.value, item])
            ).values()
          ];
        }
      );
    },
    modeloPossiveis(modeloTiposVmodel) {
      if (modeloTiposVmodel) {
        this.modeloTipos.dropdown.selecionado = modeloTiposVmodel;
      }
      this.modelosPossiveisVariable =
        this.modelo.dropdown.modelosPossiveis.filter((v) => {
          return this.modeloTipos.dropdown.selecionado == v.mt_tipo;
        });
    },
    encontrarBancoIdDaLicenciadaSelecionada() {
      const bancoId = this.licenciada.bancos.find((v) => {
        return (
          v.sistema_licenciada_id ===
          this.objetoParaEnviarNoCadastro.sistema_licenciada_id
        );
      }).sistema_banco_id;

      return bancoId;
    },
    async getCampanhaFiltros() {
      const filtros = ApiService.get(
        API_LINKS.campanhaFiltro +
          "/" +
          this.objetoParaEnviarNoCadastro.modelo_id
      );
      return filtros;
    }
  }
};
</script>

<style lang="scss" scoped>
.cadastro-consulta-container {
  padding: 24px;
  margin: 0 !important;
}
.camp-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e4e6ef;
  padding: 10px 20px;
  margin-bottom: 20px;
  h2 {
    margin: 0;
  }
}

.camp-container {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
}

.camp-body {
  width: 100%;
  padding: 20px 20px;
  display: flex;
  flex-wrap: wrap;
}

.botao-filtro-add {
  border: 1px solid #1bc5bd;
  color: #1bc5bd;
  padding: 2px 6px;
  border-radius: 5px;
  z-index: 10;
  padding: 4px 12px;
  height: 38px;
  margin-left: auto;
}

.back-arrow-squared {
  border: 1px solid #808080;
  border-radius: 4px;
  transform: rotate(-90deg);
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
